module services {
    export module customs {

        export class refundClaimService implements interfaces.customs.IRefundClaimService {

            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            CreateRefundClaim(ownerEntityId: number, refundClaimTypeId: number, counterId?: number, referenceNumber?: string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/CreateRefundClaim", {
                    ownerEntityId: ownerEntityId,
                    refundClaimTypeId: refundClaimTypeId,
                    counterId: counterId,
                    referenceNumber: referenceNumber
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            getList(): ng.resource.IResourceClass<interfaces.customs.IRefundClaimDisplay> {
                return this.$resource<interfaces.customs.IRefundClaimDisplay>(this.ENV.DSP_URL + "RefundClaim/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

            GetCurrentGoodsDeclarations(): ng.resource.IResourceClass<interfaces.customs.IRefundGoodsDeclarationDisplay> {
                return this.$resource<interfaces.customs.IRefundGoodsDeclarationDisplay>(this.ENV.DSP_URL + "RefundClaim/GetCurrentGoodsDeclarations", {
                    refId: '@refId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            getAvailableGoodsDeclarations(): ng.resource.IResourceClass<interfaces.customs.IRefundGoodsDeclarationDisplay> {
                return this.$resource<interfaces.customs.IRefundGoodsDeclarationDisplay>(this.ENV.DSP_URL + "RefundClaim/GetAvailableGoodsDeclarations", {
                    ownerEntityId: '@ownerEntityId',
                    refundClaimTypeId: '@refundClaimTypeId',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            getRefundClaimsExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'RefundClaim/GetRefundClaimsExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));
                url += '&IsInbound=' + params.isInbound;

                if (params.invoiceNumber)
                    url += '&invoiceNumber=' + params.invoiceNumber;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getRefundClaimDetail(): ng.resource.IResourceClass<interfaces.customs.IRefundClaim> {
                return this.$resource<interfaces.customs.IRefundClaim>(this.ENV.DSP_URL + "RefundClaim/GetRefundClaimDetail",
                    {
                        refId: "@refId",
                        isClaim: "@isClaim",
                        IsErrorsWarning: "@IsErrorsWarning"
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

            saveRefundClaim(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/SaveRefundClaim", {
                });
            }   

            UpdateRefundClaim(refId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/UpdateRefundClaim", {
                    refId: refId
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            ValidateRefundClaim(refId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/ValidateRefundClaim", {
                    refId: refId
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            GenerateRefundClaimDocuments(refId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/GenerateRefundClaimDocuments", {
                    refId: refId
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            changeStatus(): ng.resource.IResourceClass<interfaces.customs.IRefundClaim> {
                return this.$resource<interfaces.customs.IRefundClaim>(this.ENV.DSP_URL + "RefundClaim/ChangeStatus", {
                }, {
                        save: {
                            method: 'POST'
                        }
                    });
            }

            CreateNewVersion(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/CreateNewVersion", {
                    id: '@id',
                    functionClassificationValueId: '@functionClassificationValueId',
                    reason: '@reason'
                });
            }

            GetCurrentErrorMessages(): ng.resource.IResourceClass<interfaces.customs.IDutyDrawbackClaimErrors> {
                return this.$resource<interfaces.customs.IDutyDrawbackClaimErrors>(this.ENV.DSP_URL + "RefundClaim/GetCurrentErrorMessages", {
                    refId: '@refId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            SignOffClaim(refId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/SignOffClaim", {
                    refId: refId
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            CalculateDA64ClaimAmount(id: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/CalculateDA64ClaimAmount", {
                    id: id
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            UnsignOffClaim(refId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/UnsignOffClaim", {
                    refId: refId
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            RerunDutyRefundCalculation(refId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/RerunDutyRefundCalculation", {
                    refId: refId
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            RecalculateTariffCodeAndOrigin(refId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/RecalculateTariffCodeAndOrigin", {
                    refId: refId
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            CollateImportDocuments(refId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/CollateImportDocuments", {
                    refId: refId
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            GetImportMRNDisplay(): ng.resource.IResourceClass<interfaces.customs.IImportMRNDisplay> {
                return this.$resource<interfaces.customs.IImportMRNDisplay>(this.ENV.DSP_URL + "RefundClaim/GetImportMRNDisplay", {
                    refId: '@refId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            DA63EditLine(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/DA63EditLine", {
                    da63LineId: '@da63LineId',
                    reasonForChange: '@reasonForChange',
                    removeFromClaim: '@removeFromClaim',
                },{
                    query: {
                        method: 'POST',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                    }
                }             
                );
            }

            saveAndValidateEDI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/SaveAndValidateEDI");
            }

            validateAndSendEDI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/ValidateandSendEDI");
            }

            createNewVersionEDI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/CreateNewVersionEDI", {
                    id: '@id',
                    functionClassificationValueId: '@functionClassificationValueId',
                    reason: '@reason'
                });
            }

            queueRefundDocumentsToCase(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundClaim/QueueRefundDocumentsToCase", {
                    id: '@id',
                });
            }

            GetDutyDrawbackClaimValidationLog(): ng.resource.IResourceClass<interfaces.customs.IDutyDrawbackClaimValidationLog> {
                return this.$resource<interfaces.customs.IDutyDrawbackClaimValidationLog>(this.ENV.DSP_URL + "RefundClaim/GetDutyDrawbackClaimValidationLog", {
                    commercialInvoiceSkuId: '@commercialInvoiceSkuId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            getRefundClaimDetailExcel(dutyDrawbackClaimId:number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'RefundClaim/GetRefundClaimDetailExcel?dutyDrawbackClaimId='+dutyDrawbackClaimId;



                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'RefundClaim.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }               
            
            getRefundClaimDetailCSV(dutyDrawbackClaimId:number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'RefundClaim/GetRefundClaimDetailCSV?dutyDrawbackClaimId='+dutyDrawbackClaimId;



                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'RefundClaim.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }                


        }
    }
    angular.module("app").service("refundClaimService", services.customs.refundClaimService);
}